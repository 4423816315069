// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-readonly-textbox .dx-texteditor-input {
  color: #000; /* Black text for better contrast */
  background-color: #f0f0f0; /* Light gray background */
  border: 1px solid #ccc; /* Border to make the field visible */
  padding: 5px; /* Extra padding for readability */
}

.custom-readonly-textbox .dx-texteditor-container {
  background-color: #f0f0f0; /* Ensure background matches */
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/inspection/inspection.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAE,mCAAmC;EAChD,yBAAyB,EAAE,0BAA0B;EACrD,sBAAsB,EAAE,qCAAqC;EAC7D,YAAY,EAAE,kCAAkC;AAClD;;AAEA;EACE,yBAAyB,EAAE,8BAA8B;AAC3D","sourcesContent":[".custom-readonly-textbox .dx-texteditor-input {\r\n  color: #000; /* Black text for better contrast */\r\n  background-color: #f0f0f0; /* Light gray background */\r\n  border: 1px solid #ccc; /* Border to make the field visible */\r\n  padding: 5px; /* Extra padding for readability */\r\n}\r\n\r\n.custom-readonly-textbox .dx-texteditor-container {\r\n  background-color: #f0f0f0; /* Ensure background matches */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
