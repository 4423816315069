import { Component, OnInit } from "@angular/core";
import { FleetOverviewReport } from "src/app/shared/Models/FleetOverviewReport";
import { ReportService } from "../report.service";
import { Router } from "@angular/router";
import { VesselService } from "src/app/shared/services/vessels.service";
import { ServerResponse } from "src/app/shared/Models/ServerResponse";
import { Vessel } from "src/app/shared/Models/Vessel";
import { VesselType } from "src/app/shared/Models/VesselType";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver-es';


@Component({
  selector: "app-fleet-overview",
  templateUrl: "./fleet-overview.component.html",
  styleUrl: "./fleet-overview.component.css",
})
export class FleetOverviewComponent implements OnInit {
  FleetOverviewOptions: FleetOverviewReport[];
  Vessel: Vessel[];
  vesselTypes:VesselType[]=[];
  constructor(
    private _vesselService:VesselService,
     public reportservice: ReportService,
     public _route: Router) {

  }
  ngOnInit() {
    this.getVesssel();
  }
  BacktoPage() {
    this._route.navigate(["/report"]);
  }
  getVesssel() {
    this._vesselService.getVesselType().subscribe((vessels: any[]) => {
      this.vesselTypes=vessels;
      this._vesselService.getVessels().subscribe(
        (response) => {
          this.Vessel=response;
          if(this.Vessel.length>0){
            this.Vessel.forEach(x=>{
              x.typeName=this.vesselTypes.find(t=>t.id==x.vesselTypeId).name;
            })
          }
        },
        (error) => {
        }
      );
    });
  }


  onExporting(e: any) {
    const grid = e.component;

    // Access the columns from the grid component
    const columns = grid.getVisibleColumns();
    const columnHeaders = columns.map(col => col.dataField);

    // Access the data source and load the data
    const dataSource = grid.getDataSource();
    dataSource.load().then(data => {
      // Create a worksheet from the data and add headers
      const worksheet = XLSX.utils.json_to_sheet(data, {
        header: columnHeaders,
      });

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Employees');

      // Write the workbook to a buffer
      const buffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });

      // Save the file using file-saver
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      saveAs(blob, 'VesselOverview.xlsx');

      // Prevent the default export behavior if needed
      e.cancel = true;
    });
  }



}
