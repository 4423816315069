import { CheckList } from "./CheckList";

export class Sprint {
  Id: number;
  Name: string;
  PlannedStartDate: string;
  ActualStartDate: string;
  Status: string;
}

export class Question {
  id: number;
  text: string;
  shortText: string;
  chapterId: number;
  roviq: string;
  objective: string;
  industryGuidance: string;
  inspectionGuidance: string;
  suggestedInspectorAction: string;
  expectedEvidence: string;
  groundsForNegativeObservation: string;
  referenceNumber: string;
  CheckList: CheckList[];
}

export class Chapter {
  Id: number;
  name: string;
  Questions?: Question[];
  referenceNumber:string;
}
