export class Inspection{
  name:string;
  vesselId:number;
  vesselName:string;
  inspectorName:string;
  startDateC:Date;
  inspectionCompany:string;
  oilMajor:string;
  placeOfInspection:string;
  startDate:string;
  endDateC:Date;
  endDate:string;
  operation:string;
}
