import { CommonModule } from "@angular/common";
import { Component, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import {
  DxButtonModule,
  DxCheckBoxModule,
  DxFormModule,
} from "devextreme-angular";
import { DxTextBoxModule } from "devextreme-angular/ui/text-box";
import { DxValidationGroupModule } from "devextreme-angular/ui/validation-group";
import { DxValidatorModule } from "devextreme-angular/ui/validator";
import { AppInfoService, AuthService } from "../../services";
declare var $: any;
import { TabService } from "../../services/tab.service";
import { LoggerService } from "../../services/loger.service";
import { SpinnerService } from "../../services/spinner.service";

@Component({
  selector: "app-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
})
export class LoginFormComponent {
  credentialsNotValid: boolean = false;
  login = "";
  password = "";
  remember = false;
  errorMessage: string;
  buttonOptions: any = {
    text: "Register",
    type: "default",
    useSubmitBehavior: true,
  };

  constructor(
    private authService: AuthService,
    public appInfo: AppInfoService,
    private tabService:TabService,
    private _loggerService:LoggerService,
    private _spinnerService:SpinnerService
  ) {
    this.focusTextField();
  }

  focusTextField() {
    setTimeout(function () {
      $("#username_login .dx-texteditor-input").focus();
    }, 0);
  }

  isPasswordVisible = false;

togglePasswordVisibility() {
  this.isPasswordVisible = !this.isPasswordVisible;
}
  onLoginClick = (args) => {
    this.errorMessage = "";
    args.preventDefault();
    this._spinnerService.showLoader();
    this.authService.logIn(this.login, this.password, this.remember).subscribe(
      (result) => {
        if (result) {
          this._spinnerService.hideLoader();
          this.credentialsNotValid = false;
          localStorage.setItem("token", result.token);
          localStorage.setItem("roleId", result.roleId); // Assuming you need to set roleId as well
          localStorage.setItem("vesselname", result.vesselName);
          localStorage.setItem("name",result.name);
          localStorage.setItem("designation",result.designation);
          this.tabService.updateTabs();
        } else {
          this.credentialsNotValid = true;
          this._spinnerService.hideLoader();
        }
      },
      (error) => {
        this._spinnerService.hideLoader();
        this.credentialsNotValid = true;
        this._loggerService.logError(error.error.message);
      }
    );
  };
}
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DxTextBoxModule,
    DxValidatorModule,
    DxValidationGroupModule,
    BrowserModule,
    DxCheckBoxModule,
    DxButtonModule,
    DxFormModule,
  ],
  declarations: [LoginFormComponent],
  exports: [LoginFormComponent],
})
export class LoginFormModule {}
