import { Component, OnDestroy, OnInit } from "@angular/core";
import { Reports } from "src/app/shared/Models/Reports";
import { ReportService } from "./report.service";
import { Router } from "@angular/router";
import { ChatService } from "src/app/shared/services/ChatService";
import { Subject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';


@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrl: "./report.component.css",
})
export class ReportComponent implements OnInit,OnDestroy {
  messages: { user: string; message: string }[] = [];
  newMessage = '';
  ReportsOptions: string[];
  report: Reports = new Reports();
  currentUser: string = localStorage.getItem("name");
  isTyping: boolean = false;
  typingSubject: Subject<void> = new Subject();
  typingTimeout: any;
  OtherUser:string;
  emojiPickerVisible: boolean = false;

  constructor(
    public reportservice: ReportService,
    public router: Router,
    private chatService: ChatService

  ) {
    this.ReportsOptions = this.reportservice
      .getReports()
      .map((report) => report.Name);
  }
  ngOnInit() {
    // this.chatService.startConnection();
    // this.chatService.onReceiveMessage((user, message) => {
    //   this.messages.push({ user, message });
    // });
    // this.chatService.onUserTyping((user) => {
    //   if (user !== this.currentUser) {
    //     this.OtherUser=user;
    //     this.isTyping = true;
    //     setTimeout(() => this.isTyping = false, 2000); // Typing indicator duration
    //   }
    // });
    // // Debounce user typing events to send only after a small delay
    // this.typingSubject.pipe(debounce(() => timer(1000))).subscribe(() => {
    //   this.sendTypingNotification();
    // });
  }
  sendMessage() {

    this.chatService.sendMessage(localStorage.getItem("name"), this.newMessage);
    this.newMessage = '';
    this.isTyping = false;

  }
  showDetails(type: string) {
    switch (type) {
      case "Fleet Overview":
        this.router.navigate(["/report/fleet-overview"]);
        break;
      case "Audit Preparation Status":
        this.router.navigate(["/report/audit-preparation"]);
        break;
      case "Defect Analysis Report":
        this.router.navigate(["/report/defect-analysis"]);
        break;
      case "Crew Performance Report":
        this.router.navigate(["/report/crew-performance"]);
        break;
      case "Historical Audit Data":
        this.router.navigate(["/report/historical-audit-data"]);
        break;
      case "User Activity Log":
        this.router.navigate(["/report/user-activity-log"]);
        break;
      default:
        this.router.navigate(["/report"]);
        break;
    }
  }
  onTyping() {
    // Trigger the typing indicator
    this.typingSubject.next();
  }

  sendTypingNotification() {
    this.chatService.notifyTyping(this.currentUser); // Notify others that the user is typing
  }
  toggleEmojiPicker() {
    this.emojiPickerVisible = !this.emojiPickerVisible;
  }

  addEmoji(event: any) {
    this.newMessage += event.detail.unicode; // Append the selected emoji to the message
    this.toggleEmojiPicker(); // Hide the emoji picker after selection
  }
  ngOnDestroy() {
    this.chatService.closeConnection();
  }
}
