export class Vessel {
  id: number;
  name: string;
  typeId: number;
  typeName: string;
  imoNumber: number;
  vesselTypeId:number;
  vlientId:string;
  nextInspectionDate:string;
  lastInspectionDate:string;
  preparednessStatus:string;
  riskScore:string;
  riskStatus:string;
}
