// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginBg {
  size: 15px;
  height: 20px;
  width: 20px;
}
.small-icon .dx-button-icon {
  width: 16px;   /* Adjust width */
  height: 16px;  /* Adjust height */
}
::ng-deep .small-icon .dx-button-icon {
  width: 16px;    /* Set desired width */
  height: 16px;   /* Set desired height */
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/program/sprints/sprints.component.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,WAAW;AACb;AACA;EACE,WAAW,IAAI,iBAAiB;EAChC,YAAY,GAAG,kBAAkB;AACnC;AACA;EACE,WAAW,KAAK,sBAAsB;EACtC,YAAY,IAAI,uBAAuB;AACzC","sourcesContent":[".loginBg {\r\n  size: 15px;\r\n  height: 20px;\r\n  width: 20px;\r\n}\r\n.small-icon .dx-button-icon {\r\n  width: 16px;   /* Adjust width */\r\n  height: 16px;  /* Adjust height */\r\n}\r\n::ng-deep .small-icon .dx-button-icon {\r\n  width: 16px;    /* Set desired width */\r\n  height: 16px;   /* Set desired height */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
