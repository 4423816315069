import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
declare var alertify: any;

enum enToastPosition {
  bottom_right = <any>'bottom-right',
  top_right = <any>'top-right'
}
class ErrorMessage {
  "Id": string;
  "ErrorCode": string;
  "Message": string;
}
enum enLogType {
  Warning = 1,
  Error = 2,
  Success = 3,
  Log = 4
}

@Injectable({
  providedIn: 'root'
})
export class LoggerService {
  private _alertify: any = alertify;
  private delay: number = 5;// (in Sec) 0 means notification never goes off, you can click to dismiss
  errorFilePath: string = "";
  errorData: string;
  constructor(private http: HttpClient, private toastr: ToastrService) {

  }


  toastrInfo(errorCode: string) {
    this.getErrorMessageByErrorCode(errorCode, enLogType.Log);
  }
  log(message: string, showToast?: boolean) {
    this.logIt(message, (showToast === undefined) ? true : showToast, 'info');
  }

  toastrWarning(errorCode: string) {
    this.getErrorMessageByErrorCode(errorCode, enLogType.Warning);
  }

  logWarning(message: string, showToast?: boolean) {
    this.logIt(message, (showToast === undefined) ? true : showToast, 'warning');
  }

  toastrSuccess(errorCode: string) {
    this.getErrorMessageByErrorCode(errorCode, enLogType.Success);
  }
  logSuccess(message: string, showToast?: boolean) {
    this.logIt(message, (showToast === undefined) ? true : showToast, 'success');
  }

  toastrError(errorCode: string) {
    this.getErrorMessageByErrorCode(errorCode, enLogType.Error);
  }

  logError(message: string, showToast?: boolean) {
    if(message.toLowerCase().indexOf("please wait")>-1)
      this.logIt(message, (showToast === undefined) ? true : showToast, 'info');
    else
      this.logIt(message, (showToast === undefined) ? true : showToast, 'error');
  }





  logIt(message: string, showToast: boolean, toastType: string) {
    if (showToast) {
      if (toastType === 'error') {
        this.toastr.error(message);
      } else if (toastType === 'warning') {
        this.toastr.warning(message);
      } else if (toastType === 'success') {
        this.toastr.success(message);
      } else {
        this.toastr.info(message);
      }
    }
  }
  //Confirmation Box
  confirmationDialog(message: string, okCallback: () => any) {
    this._alertify.confirm(message, function (e) {
      if (e) {
        okCallback();
      } else {
      }
    });
  }

  public getErrorMessageByErrorCode(errorCode: string, logType: number) {
    this.http.get(this.errorFilePath).subscribe((response: any) => {
      let data = response.json().find(a => a.ErrorCode == errorCode);
      this.errorData = data == null ? undefined : data.Message;
      switch (logType) {
        case enLogType.Log:
          this.toastr.info(this.errorData);
          break;
        case enLogType.Warning:
          this.toastr.warning(this.errorData);
          break;
        case enLogType.Error:
          this.toastr.error(this.errorData);
          break;
        case enLogType.Success:
          this.toastr.success(this.errorData);
          break;
        default:
          //nothing
          break;
      }
    }, (error: any) => {
      //console.log(error);
    })
  }

  /**
     * It will fetch the Error message from the global error file by the errorCode
     * @param errorCode
     */
  logErrorCode(errorCode: string) {
    this.getErrorMessageByErrorCode(errorCode, enLogType.Log);
  }
























  /**
   * It will fetch the Error message from the global error file by the errorCode
   * will show a warning message
   * @param errorCode
   */
  Warning(errorCode: string) {
    this.getErrorMessageByErrorCode(errorCode, enLogType.Warning);
  }

  /**
   * It will fetch the Error message from the global error file by the errorCode
   * Will show a Succes message
   * @param errorCode
   */
  Success(errorCode: string) {
    this.getErrorMessageByErrorCode(errorCode, enLogType.Success);
  }


  /**
   * It will fetch the Error message from the global error file by the errorCode
   * will show a error message
   * @param errorCode
   */
  Error(errorCode: string) {
    this.getErrorMessageByErrorCode(errorCode, enLogType.Error);

  }

  confirmationDialogbox(errorCode: string, okCallback: () => any) {
    this.http.get(this.errorFilePath).subscribe((response: any) => {
      let data = response.json().find(a => a.ErrorCode == errorCode);
      this.errorData = data == null ? undefined : data.Message;
      this._alertify.confirm(this.errorData, function (e) {
        if (e) {
          okCallback();
        } else {
        }
      }).setHeader('<em> </em> ');
    }, (error: any) => {
      //console.log(error);
    })
  }

}
