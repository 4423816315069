import { AccordionModule } from 'primeng/accordion';
    import { AutoCompleteModule } from 'primeng/autocomplete';
    import { AvatarModule } from 'primeng/avatar';
    import { AvatarGroupModule } from 'primeng/avatargroup';
    import { BadgeModule } from 'primeng/badge';
    import { BreadcrumbModule } from 'primeng/breadcrumb';
    import { ButtonModule } from 'primeng/button';
    import { ButtonGroupModule } from 'primeng/buttongroup';
    import { CalendarModule } from 'primeng/calendar';
    import { CarouselModule } from 'primeng/carousel';
    import { CascadeSelectModule } from 'primeng/cascadeselect';
    import { ChartModule } from 'primeng/chart';
    import { CheckboxModule } from 'primeng/checkbox';
    import { ChipModule } from 'primeng/chip';
    import { ChipsModule } from 'primeng/chips';
    import { ConfirmDialogModule } from 'primeng/confirmdialog';
    import { ConfirmPopupModule } from 'primeng/confirmpopup';
    import { ColorPickerModule } from 'primeng/colorpicker';
    import { ContextMenuModule } from 'primeng/contextmenu';
    import { DataViewModule } from 'primeng/dataview';
    import { VirtualScrollerModule } from 'primeng/virtualscroller';
    import { DialogModule } from 'primeng/dialog';
    import { DividerModule } from 'primeng/divider';
    import { DockModule } from 'primeng/dock';
    import { DragDropModule } from 'primeng/dragdrop';
    import { DropdownModule } from 'primeng/dropdown';
    import { DynamicDialogModule } from 'primeng/dynamicdialog';
    import { DeferModule } from 'primeng/defer';
    import { EditorModule } from 'primeng/editor';
    import { FieldsetModule } from 'primeng/fieldset';
    import { FileUploadModule } from 'primeng/fileupload';
    import { FocusTrapModule } from 'primeng/focustrap';
    import { GalleriaModule } from 'primeng/galleria';
    import { InplaceModule } from 'primeng/inplace';
    import { InputMaskModule } from 'primeng/inputmask';
    import { InputSwitchModule } from 'primeng/inputswitch';
    import { InputTextModule } from 'primeng/inputtext';
    import { InputNumberModule } from 'primeng/inputnumber';
    import { InputTextareaModule } from 'primeng/inputtextarea';
    import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
    import { InputGroupModule } from 'primeng/inputgroup';
    import { InputOtpModule } from 'primeng/inputotp';
    import { ImageModule } from 'primeng/image';
    import { KnobModule } from 'primeng/knob';
    import { ListboxModule } from 'primeng/listbox';
    import { MegaMenuModule } from 'primeng/megamenu';
    import { MenuModule } from 'primeng/menu';
    import { MenubarModule } from 'primeng/menubar';
    import { MessageModule } from 'primeng/message';
    import { MessagesModule } from 'primeng/messages';
    import { MultiSelectModule } from 'primeng/multiselect';
    import { MeterGroupModule } from 'primeng/metergroup';
    import { OrderListModule } from 'primeng/orderlist';
    import { OrganizationChartModule } from 'primeng/organizationchart';
    import { OverlayPanelModule } from 'primeng/overlaypanel';
    import { PaginatorModule } from 'primeng/paginator';
    import { PanelModule } from 'primeng/panel';
    import { PanelMenuModule } from 'primeng/panelmenu';
    import { PasswordModule } from 'primeng/password';
    import { PickListModule } from 'primeng/picklist';
    import { ProgressBarModule } from 'primeng/progressbar';
    import { RadioButtonModule } from 'primeng/radiobutton';
    import { RatingModule } from 'primeng/rating';
    import { ScrollerModule } from 'primeng/scroller';
    import { ScrollPanelModule } from 'primeng/scrollpanel';
    import { ScrollTopModule } from 'primeng/scrolltop';
    import { SelectButtonModule } from 'primeng/selectbutton';
    import { SidebarModule } from 'primeng/sidebar';
    import { SkeletonModule } from 'primeng/skeleton';
    import { SlideMenuModule } from 'primeng/slidemenu';
    import { SliderModule } from 'primeng/slider';
    import { SpeedDialModule } from 'primeng/speeddial';
    import { SpinnerModule } from 'primeng/spinner';
    import { SplitButtonModule } from 'primeng/splitbutton';
    import { SplitterModule } from 'primeng/splitter';
    import { StepperModule } from 'primeng/stepper';
    import { StepsModule } from 'primeng/steps';
    import { TabMenuModule } from 'primeng/tabmenu';
    import { TableModule } from 'primeng/table';
    import { TabViewModule } from 'primeng/tabview';
    import { TagModule } from 'primeng/tag';
    import { TerminalModule } from 'primeng/terminal';
    import { TieredMenuModule } from 'primeng/tieredmenu';
    import { TimelineModule } from 'primeng/timeline';
    import { ToastModule } from 'primeng/toast';
    import { ToggleButtonModule } from 'primeng/togglebutton';
    import { ToolbarModule } from 'primeng/toolbar';
    import { TooltipModule } from 'primeng/tooltip';
    import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
    import { TreeModule } from 'primeng/tree';
    import { TreeSelectModule } from 'primeng/treeselect';
    import { TreeTableModule } from 'primeng/treetable';
    import { AnimateModule } from 'primeng/animate';
    import { AnimateOnScrollModule } from 'primeng/animateonscroll';
    import { CardModule } from 'primeng/card';
    import { BlockUIModule } from 'primeng/blockui';
    import { ProgressSpinnerModule } from 'primeng/progressspinner';
    import { RippleModule } from 'primeng/ripple';
    import { FloatLabelModule } from 'primeng/floatlabel';
    import { IconFieldModule } from 'primeng/iconfield';
    import { InputIconModule } from 'primeng/inputicon';
    import { StyleClassModule } from 'primeng/styleclass';
    import { AutoFocusModule } from 'primeng/autofocus';
    import { FormsModule, ReactiveFormsModule } from '@angular/forms';
    import { HttpClientModule } from '@angular/common/http';
    import { NgModule } from '@angular/core';
import { DxTextBoxModule } from 'devextreme-angular/ui/text-box';

    @NgModule({
      imports: [
        AvatarModule,
        AvatarGroupModule,
        AnimateOnScrollModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        AccordionModule,
        AutoCompleteModule,
        BadgeModule,
        BreadcrumbModule,
        BlockUIModule,
        ButtonModule,
        ButtonGroupModule,
        CalendarModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        ChipModule,
        ColorPickerModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ContextMenuModule,
        VirtualScrollerModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DockModule,
        DragDropModule,
        DropdownModule,
        DynamicDialogModule,
        DeferModule,
        EditorModule,
        FieldsetModule,
        FileUploadModule,
        FocusTrapModule,
        GalleriaModule,
        InplaceModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        InputNumberModule,
        InputGroupModule,
        InputGroupAddonModule,
        InputOtpModule,
        ImageModule,
        KnobModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        MeterGroupModule,
        OrganizationChartModule,
        OrderListModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressSpinnerModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        SelectButtonModule,
        SidebarModule,
        ScrollerModule,
        ScrollPanelModule,
        ScrollTopModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SpeedDialModule,
        SpinnerModule,
        SplitterModule,
        StepperModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TieredMenuModule,
        TimelineModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TriStateCheckboxModule,
        TreeModule,
        TreeSelectModule,
        TreeTableModule,
        AnimateModule,
        CardModule,
        RippleModule,
        StyleClassModule,
        IconFieldModule,
        InputIconModule,
        AutoFocusModule
      ],
      exports: [
        DxTextBoxModule,
        AvatarModule,
        AvatarGroupModule,
        AnimateOnScrollModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        AccordionModule,
        AutoCompleteModule,
        BadgeModule,
        BreadcrumbModule,
        BlockUIModule,
        ButtonModule,
        ButtonGroupModule,
        CalendarModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        ChipModule,
        ColorPickerModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ContextMenuModule,
        VirtualScrollerModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DeferModule,
        DockModule,
        DragDropModule,
        DropdownModule,
        DynamicDialogModule,
        EditorModule,
        FieldsetModule,
        FileUploadModule,
        FocusTrapModule,
        GalleriaModule,
        InplaceModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        InputNumberModule,
        InputGroupModule,
        InputGroupAddonModule,
        InputOtpModule,
        ImageModule,
        KnobModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        MeterGroupModule,
        OrganizationChartModule,
        OrderListModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressSpinnerModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        SelectButtonModule,
        SidebarModule,
        ScrollerModule,
        ScrollPanelModule,
        ScrollTopModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SpeedDialModule,
        SpinnerModule,
        SplitterModule,
        StepperModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TieredMenuModule,
        TimelineModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TriStateCheckboxModule,
        TreeModule,
        TreeSelectModule,
        TreeTableModule,
        AnimateModule,
        CardModule,
        RippleModule,
        StyleClassModule,
        FloatLabelModule,
        IconFieldModule,
        InputIconModule,
        AutoFocusModule
      ],
      providers: [  ]
    })
    export class ImportsModule {}
