export class Checklist {
  id: number;
  name: string;
  questionId: number;
  remarks: string;
  question: any;
  sprintCheckListQuestions: any[];
}

export class SprintChecklistQuestion {
  id: number;
  checklistId: number;
  yesNo: boolean;
  customChecklistId: number;
  sprintQuestionId: number;
  checklist: Checklist;
  customChecklist: any;
  sprintAnswer: any[];
  sprintQuestion: any;
}

export class Status {
  id: number;
  name: string;
  isActive: boolean;
  program: any[];
  sprint: any[];
  sprintAnswer: any[];
  sprintQuestion: any[];
}

export class SprintAnswer {
  id: number;
  crewId: number;
  answer: string;
  masterNote: string;
  statusId: number;
  count: number;
  createdBy: number;
  createdOn: string;
  modifiedBy: number;
  modifiedOn: string;
  deletedBy: number;
  deletedOn: string;
  isActive: boolean;
  sprintChecklistQuestionId: number;
  sprintQuestionId: number;
  createdByNavigation: any;
  crew: any;
  deletedByNavigation: any;
  modifiedByNavigation: any;
  sprintChecklistQuestion: SprintChecklistQuestion;
  status: Status;
  checklist: any;
  isReferenceMaterialSeen: boolean;
  tentativeClosureDateValue:Date;
  tentativeClosureDate:string;
  expectation:boolean;
  image:any;
}
