import { Injectable } from "@angular/core";
import { Vessel } from "src/app/shared/Models/Vessel";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ServerResponse } from "../Models/ServerResponse";

@Injectable()
export class VesselService {
  constructor(private http: HttpClient) {}
  private token: string | null = null;

  getHeaders(): HttpHeaders {
    this.token = localStorage.getItem("token");
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${this.token}`,
    });
    return headers;
  }
  readonly apiURL = environment.server;
  data: any;
  options = {
    headers: this.getHeaders(),
  };
  private toastr: ToastrService;
  getVessels() {
    return this.http.get<any[]>(this.apiURL + "Vessel/GetVessel", this.options);
  }
  getVesselType(){
    return this.http.get<any[]>(this.apiURL + "Vessel/GetVesselType", this.options);
  }
  getDefect(){
    return this.http.post<ServerResponse>(this.apiURL + "Vessel/GetDefect", this.options);
  }
  closeDefect(id:string){
    return this.http.post<ServerResponse>(this.apiURL + "Vessel/GetDefect/"+id, this.options);
  }
  getVesselsById(id: any) {
    return this.http.post<any[]>(
      this.apiURL + "Vessel/GetById/{id}",
      id,
      this.options
    );
  }
  saveData(requestBody: any) {
    return this.http.post<any[]>(
      this.apiURL + "Vessel/AddVessel",
      requestBody,
      this.options
    );
  }

  deleteData(id: any) {
    return this.http.post<any[]>(
      this.apiURL + "Vessel/DeleteVessel",
      id,
      this.options
    );
  }
  editData(requestBody: any) {
    return this.http.put<any[]>(
      this.apiURL + "Vessel/EditVessel",
      requestBody,
      this.options
    );
  }
}
