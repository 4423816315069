export class Program {
  id: number;
  name: string;
  plannedStartDate: Date;
  createdBy: number;
  createdOn: Date;
  vesselId: number;
  statusId: number;
  clientId: string;
  createdByNavigation: any;
  sprint: any[];
  status: any;
  vessel: any;
}
