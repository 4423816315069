import { Component, OnInit } from "@angular/core";
import { ReportService } from "../report.service";
import { Router } from "@angular/router";
import { DefectAnalysisReport } from "src/app/shared/Models/DefectAnalysisReport";
import { SpinnerService } from "src/app/shared/services/spinner.service";
import { VesselService } from "src/app/shared/services/vessels.service";
import { Defect } from "src/app/shared/Models/Defect";
import { ServerResponse } from "src/app/shared/Models/ServerResponse";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver-es';

@Component({
  selector: "app-defect-analysis",
  templateUrl: "./defect-analysis.component.html",
  styleUrl: "./defect-analysis.component.css",
})
export class DefectAnalysisComponent implements OnInit {
  DefectAnalysisOptions: DefectAnalysisReport[];
  defectList:defectAnalysis[]=[];
  constructor(
    public reportservice: ReportService,
    private _vesselService:VesselService,
    public _route: Router,
    private _spinnerService:SpinnerService,

    ) {
    this.DefectAnalysisOptions = reportservice.getDefectAnalysisReports();
  }
  ngOnInit() {
    this.getDefect()
  }
  BacktoPage() {
    this._route.navigate(["/report"]);
  }
  getDefect(){
    this._spinnerService.showLoader();
    this._vesselService.getDefect().subscribe((res:ServerResponse)=>{
      if(res.success){
        this._spinnerService.hideLoader();
        this.defectList=res.result;

     this.defectList =   res.result.map((x:any) => ({
          vesselName : x.user.vessel.name,
  defectDesc:x.sprintAnswer.answer==null?"":x.sprintAnswer.answer,
  creationTime: x.creationTime,
  status : x.statusName,
  timeElapsed:x.timeElapsed,
        }));
      }
    },(error:Error)=>{
      this._spinnerService.hideLoader();
    })
  }
  onExporting(e: any) {
    const grid = e.component;

    // Access the columns from the grid component
    const columns = grid.getVisibleColumns();
    const columnHeaders = columns.map(col => col.dataField);

    // Access the data source and load the data
    // const dataSource = grid.getDataSource();
    // dataSource.load().then(data => {
    //   // Create a worksheet from the data and add headers
    //   const worksheet = XLSX.utils.json_to_sheet(data, {
    //     header: columnHeaders,
    //   });
    const dataSource = grid.getDataSource();
  dataSource.load().then(data => {
    // Map data to include only the visible columns
    const exportData = data.map(row =>
      columns.reduce((acc, col) => {
        const dataField = col.dataField;
        acc[col.caption || dataField] = row[dataField]; // Use column caption as the header
        return acc;
      }, {})
    );

    // Create a worksheet with processed data
    const worksheet = XLSX.utils.json_to_sheet(exportData);

      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Employees');

      // Write the workbook to a buffer
      const buffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });

      // Save the file using file-saver
      const blob = new Blob([buffer], { type: 'application/octet-stream' });
      saveAs(blob, 'DefectAnalysis.xlsx');

      // Prevent the default export behavior if needed
      e.cancel = true;
    });
  }
}
export class defectAnalysis{
  vesselName:string;
  defectDesc:string;
  creationTime:string;
  status:string;
  timeElapsed:string;
}
