import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgramService } from '../program.service';
import {SpinnerService} from '../../../shared/services/spinner.service';
import { ServerResponse } from 'src/app/shared/Models/ServerResponse';
import { LoggerService } from 'src/app/shared/services/loger.service';
import { CaptainService } from 'src/app/shared/services/captain.service';


@Component({
  selector: 'app-details',
  templateUrl: './sprints.component.html',
  styleUrl: './sprints.component.css',
})
export class DetailsComponent implements OnInit {
  sprints: any[] = [];
  originalSprints: any[] = [];
  programId: string;
  expanded: Boolean = true;
  expandedRowKeys: Number[] = [];
  sprintdatatoPass: any[] = [];
  programName:string;
  vesselName:string;
  constructor(
    private programservice: ProgramService,
    private _spinnerService: SpinnerService,
    private _captainService:CaptainService,
    private _loggerService:LoggerService,
    private _router: Router,
    private route: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.programId = params['programid'];
    });
    this.getSprint(this.programId);
    this.programName = localStorage.getItem("ProgramName");
    this.vesselName = localStorage.getItem("SprintVesselName")
  }
  BacktoPrograms() {
    this._router.navigate(['/program']);
  }

  bindChapterWiseQuestions(currentSprintClickedId: number) {
    const clickedSprint = this.originalSprints.find(
      (sprint) => sprint.id === currentSprintClickedId
    );
    if (clickedSprint) {
      this.sprintdatatoPass = clickedSprint;
    } else {
    }
    return this.sprintdatatoPass;
  }
  handleItemExpanded(e: any) {
    // tslint:disable-next-line:radix
    this._spinnerService.showLoader();
    this.programservice.GetChapterWiseSprintQuestions(parseInt(e.key)).subscribe(res => {
      this._spinnerService.hideLoader();
      this.sprintdatatoPass = res.result;
    });
  }
  onRowPrepared(e: any) {
    if (e.rowType === 'data') {
       if (e.data.statusId === 6) {
        e.rowElement.style.backgroundColor = '#d4edda';  // Light red for inactive
      } else if (e.data.statusId === 7) {
        e.rowElement.style.backgroundColor = '#d4d4d4';  // Light grew for pending
      }
    }
  }
  redirectToSprintPage(sprintId: number) {
    this._router.navigate(['/sprint-details', sprintId]);
  }
  onSprintClick(v:any){
    console.log(v);
    if(v.statusId==7){
      this._loggerService.logWarning("This sprint is completed, Expand to see the questions");
      return
    }
    localStorage.setItem("SprintId",v.id);
    this._router.navigate(["/manage-vessel-questionnaire"]);
  }
  startSprint(e:any){
    console.log(e)
    if(e.statusId==6){
      this._loggerService.logError("This sprint already in progress!");
      return;
    }
    if(e.statusId==8){
      this._loggerService.logError("This sprint is closed");
      return;
    }
    if(this.sprints.findIndex(x=>x.statusId==6)!=-1 && e.statusId!=7){
      this._loggerService.logError(this.sprints[this.sprints.findIndex(x=>x.statusId==6)].name + " is in progress. You can start after this sprint is closed.");
      return;
    }
  //  let newSprints= this.sprints
  // .filter(x => x.statusId === 5)  // Filter sprints with statusId of 5
  // .sort((a, b) => new Date(a.plannedStartDate).getTime() - new Date(b.plannedStartDate).getTime());  // Sort by plannedStartDate
  // if(newSprints.length>0 && e.id!=newSprints[0].id && e.statusId==5){
  //   this._loggerService.logError("This sprint is can not be started before "+e.name+" sprint");
  //     return;
  // }


    this._spinnerService.hideLoader();
    this.programservice.startSprint(e.id).subscribe((res:ServerResponse)=>{
      this._spinnerService.hideLoader();
      if(res.success){
        this._loggerService.logSuccess("Sprint status changed !");
        this.getSprint(this.programId);
      }
    },(error:Error)=>{
      this._spinnerService.hideLoader();

    })
  }
  getSprint(programId:string){
    this.programservice.getSprint(this.programId).subscribe(
      (response) => {
        this.originalSprints = response; // Store original response
        this.sprints = response.map((sprint) => ({
          id: sprint.id,
          name: sprint.name,
          plannedStartDate: sprint.plannedStartDate,
          plannedEndDate: sprint.plannedEndDate,
          statusId: sprint.statusId,
          statusName: sprint.status.name,
        }));
        this.sprints.forEach((sprint) => {
          this.expandedRowKeys.push(sprint.id);
        });
      },
      (error) => {
        console.error('Error:', error);
      }
    );
  }
}
