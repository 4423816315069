import { Component, ElementRef, NgZone ,ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Form } from 'formiojs/types/form';
import { ToastrService } from 'ngx-toastr';
import { interval, Observable, take } from 'rxjs';
import { QuestionDto } from 'src/app/shared/Models/QuestionDTO';
import { SprintAnswer } from 'src/app/shared/Models/SprintAnswer';
import { CrewService } from 'src/app/shared/services/crewService';
import { SharedService } from 'src/app/shared/services/sharedService';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { Location } from "@angular/common";

@Component({
  selector: 'app-crew-checklist-questions',
  templateUrl: './crew-checklist-questions.component.html',
  styleUrl: './crew-checklist-questions.component.css'
})
export class CrewChecklistQuestionsComponent {
  constructor(private route: ActivatedRoute,
    private _crewService:CrewService,
    private _sharedService:SharedService,
    private _loggerService:ToastrService,
    private _spnnerService:SpinnerService,
    private _location: Location,
    private _ngZone: NgZone
  ){

  }
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  imagePreview: string | null = null;
  cameraActive = false;
  cameraDisabled = false;
  videoStream: MediaStream | null = null;
  crewChecklistQuestionid:string;
  form:any;
  popupVisible = false;
  referencePopupVisible:boolean = false;  // Show reference popup on page load
  selectedQuestion: SprintAnswer= new SprintAnswer();
  timerValue: number = 0; // Timer starting from 0 seconds
  sprintChecklistQuestions:any[]=[];
  questionId:string="";
  expectation:boolean=null;
  question:QuestionDto=new QuestionDto();
  selectedDate:any;
  minDate: Date = new Date();
  boo = false;
  speech: string = '';
  voice = '';
  private speechRecognition: any;
  isRecognizing = false;
  facingMode: string = 'user'; // Default to front camera

  ngOnInit() {
    this._spnnerService.showLoader();
    this._sharedService.currentQuestionId.subscribe(a=>{
     this.questionId=a;
    });
    this._crewService.getQuestionById(this.questionId).subscribe(res=>{
      this._spnnerService.hideLoader();
      this.question=res;
      this.form=JSON.parse( this.question.roviq);
    })
    this.crewChecklistQuestionid = this.route.snapshot.paramMap.get('id');
    this.getCrewChecklistQuestion(this.crewChecklistQuestionid);

    }

  startTimer() {
    interval(1000).subscribe(() => {
      this.timerValue++;
    });
  }
  getCurrentDate(): string {
    const currentDate = new Date();
    return currentDate.toISOString().split("T")[0];
  }

  getCrewChecklistQuestion(crewChecklistQuestionid){
    this._spnnerService.showLoader();
    this._crewService.getCrewChecklistQuestion(crewChecklistQuestionid).subscribe(res=>{
      this._spnnerService.hideLoader();
      this.sprintChecklistQuestions = res.sort((a, b) => a.statusId - b.statusId);
      this.referencePopupVisible = !this.sprintChecklistQuestions[0].isReferenceMaterialSeen;
    });
  }

  editQuestion(question: any) {
    this.selectedQuestion = { ...question };
    this.selectedQuestion.tentativeClosureDateValue = question.tentativeClosureDate;
    this.popupVisible = true;
  }

  saveAnswer(value:boolean) {
    this.stopCamera();
    if(this.selectedQuestion.answer==null || this.selectedQuestion.answer.trim()==""){
      this._loggerService.warning("Please enter Answer");
      return;
    }
    if(this.selectedQuestion.expectation==false &&  this.selectedQuestion.tentativeClosureDateValue==null){
      this._loggerService.warning("Please Select Tentative Closure Date.");
      return;
    }
    if(this.selectedQuestion.expectation==false){
      const startDate = new Date(Date.UTC(
        this.selectedQuestion.tentativeClosureDateValue.getFullYear(),
        this.selectedQuestion.tentativeClosureDateValue.getMonth(),
        this.selectedQuestion.tentativeClosureDateValue.getDate()
    ));
  // Format dates as 'YYYY-MM-DD' if necessary for the API
    this.selectedQuestion.tentativeClosureDate = startDate.toISOString().split('T')[0];
    }

    this._spnnerService.showLoader();
    this._crewService.saveChecklistAnswer(this.selectedQuestion,value).subscribe((res=>{
      this.getCrewChecklistQuestion(this.crewChecklistQuestionid);
    }));
    this.selectedQuestion =  new SprintAnswer();
    let question =this.sprintChecklistQuestions.find(x=>x.id==this.selectedQuestion.id);
    if(question!=null||undefined){
      this.sprintChecklistQuestions.find(x=>x.id==this.selectedQuestion.id).answer=this.selectedQuestion.answer;
    }
    this.popupVisible = false;
  }

  closePopup() {
    this.popupVisible = false;
    this.expectation=null;
    this.stopCamera();
  }
  startCamera(): void {
    const constraints = {
      video: {
        facingMode: this.facingMode, // Use the current facing mode
      },
    };

    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        this.videoStream = stream;
        const video = this.videoElement.nativeElement;
        video.srcObject = stream;
        video.play();
        this.cameraActive = true;
      })
      .catch((error) => {
        console.error("Error accessing camera:", error);
      });
  }

  stopCamera(): void {
    if (this.videoStream) {
      this.videoStream.getTracks().forEach((track) => track.stop());
    }
    this.cameraActive = false;
  }
  toggleCamera(): void {
    // Stop the current stream before toggling
    this.stopCamera();

    // Toggle between front and back camera
    this.facingMode = this.facingMode === 'user' ? 'environment' : 'user';

    // Restart the camera with the new facing mode
    this.startCamera();
  }
  submitForm(res:any){
    this.referencePopupVisible = false;
    this.expectation=null;
  }

  closeReferencePopup() {
    this.referencePopupVisible = false;
  }
  openReferencePopup(){
    this.referencePopupVisible = true;
  }

  onImageUpload(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const fileType = file.type;

      // Validate file type (only allow JPG and PNG)
      if (fileType === 'image/jpeg' || fileType === 'image/png') {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.selectedQuestion.image = e.target.result; // Store image as base64
        };
        reader.readAsDataURL(file);

        console.log('Image uploaded:', file);
      } else {
        this._loggerService.warning('Only JPG and PNG files are allowed.');
        input.value = ''; // Reset the input field
        this.selectedQuestion.image = null;
      }
    }
  }
  async openCamera(): Promise<void> {
    this.cameraActive = true;
    try {
      this.videoStream = await navigator.mediaDevices.getUserMedia({ video: true });
      this.videoElement.nativeElement.srcObject = this.videoStream;
    } catch (error) {
      console.error('Camera access denied:', error);
      this.cameraDisabled = true;
    }
  }

  captureImage(): void {
    if (this.videoElement) {
      const video = this.videoElement.nativeElement;
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const context = canvas.getContext('2d');
      if (context) {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        this.imagePreview = canvas.toDataURL('image/png');
        const base64String = canvas.toDataURL('image/png'); // 'image/png' specifies the format
      this.selectedQuestion.image = base64String;
      }

      // Stop the camera stream
      if (this.videoStream) {
        this.videoStream.getTracks().forEach(track => track.stop());
      }
      this.cameraActive = false;
    }
  }



  getTranscript({ locale = 'en-US' }: { locale?: string } = {}): Observable<string> {
    return new Observable(observer => {
      const SpeechRecognition = window['webkitSpeechRecognition'];
      this.speechRecognition = new SpeechRecognition();
      this.speechRecognition.continuous = true;
      this.speechRecognition.interimResults = true;
      this.speechRecognition.lang = locale;

      this.speechRecognition.onresult = (speechRecognitionEvent) => {
        let interim_transcript = '';
        for (let i = speechRecognitionEvent.resultIndex; i < speechRecognitionEvent.results.length; ++i) {
          if (speechRecognitionEvent.results[i].isFinal) {
            this.boo = true;
            this._ngZone.run(() =>
              observer.next(speechRecognitionEvent.results[i][0].transcript.trim())
            );
          } else {
            this.boo = false;
            interim_transcript += speechRecognitionEvent.results[i][0].transcript;
            this._ngZone.run(() => observer.next(interim_transcript.trim()));
          }
        }
      };

      this.speechRecognition.onstart = () => {
        this._ngZone.run(() => (this.isRecognizing = true));
      };

      this.speechRecognition.onend = () => {
        this._ngZone.run(() => (this.isRecognizing = false));
      };

      this.speechRecognition.start();

      return () => this.speechRecognition.abort();
    });
  }

  recognize() {
    if(this.selectedQuestion.answer==undefined||this.selectedQuestion.answer.trim()==""){
      this.voice= '';
    }
    this.getTranscript()
      .subscribe(transcript => {
        if (transcript !== '' && this.boo) {
          this.voice = this.voice + ' ' + transcript;
          this.selectedQuestion.answer = this.voice;
        } else {
          this.speech = transcript;
        }
      });
  }

  stop() {
    if (this.speechRecognition) {
      this.speechRecognition.stop();
      console.log('Speech recognition stopped.');
    } else {
      console.warn('Speech recognition is not active.');
    }
  }
  BacktoPage(){
    this._location.back();
}

}

