import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Defect } from 'src/app/shared/Models/Defect';
import { ServerResponse } from 'src/app/shared/Models/ServerResponse';
import { SpinnerComponent } from 'src/app/shared/components/spinner/spinner.component';
import { LoggerService } from 'src/app/shared/services/loger.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { VesselService } from 'src/app/shared/services/vessels.service';
declare var $: any;
import { Modal } from 'bootstrap';
// import { exportDataGrid } from 'devextreme/excel_exporter';
// import { saveAs } from 'file-saver-es';

@Component({
  selector: 'app-defect',
  templateUrl: './defect.component.html',
  styleUrl: './defect.component.css'
})
export class DefectComponent implements OnInit {
@ViewChild('detailsModal') detailsModal: ElementRef;
defectList:Defect[]=[];
selectedDefect: any;
constructor(
private _vesselService:VesselService,
private _spinnerService:SpinnerService,
private _loggerService:LoggerService
){

}

  ngOnInit() {
    this._spinnerService.showLoader();
    this._vesselService.getDefect().subscribe((res:ServerResponse)=>{
      if(res.success){
        this._spinnerService.hideLoader();
        this.defectList=res.result;

      }
    },(error:Error)=>{
      this._spinnerService.hideLoader();
    })
  }


  onActionClick(defect: any) {
    this.selectedDefect = defect;
    this.selectedDefect.chapterName = this.selectedDefect?.question?.text.split('.')[0] + ". " +this.selectedDefect.chapterName;
  }


  closeDefect(){
    this._spinnerService.showLoader();
    this._vesselService.closeDefect(this.selectedDefect.id).subscribe((res:ServerResponse)=>{
      this._spinnerService.hideLoader();
      if(res.success){
        this._loggerService.logSuccess("Defect has been Closed successfully.");
        this.selectedDefect.statusName = "Closed";
        this.selectedDefect.closedOn = new Date();
      }
    },(error)=>{
      this._spinnerService.hideLoader();
      this._loggerService.logError(error);
    });
    $('#detailsModal').modal('hide');
  }
  closedDefectModel(){
    $('#detailsModal').modal('hide');
  }

}

