import { Component, NgModule, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../../services';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserPanelModule } from '../user-panel/user-panel.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent {
  urlBsinfo = "api/BusinessInfo";
  bsinfoData: Bsinfo;
  businessImageUrl: any;
  tradingName: any;
  companyName: any;
  userName:string="";
  vesselname:string="";
  designation:string=""
  constructor(private authService: AuthService, public _router: Router, private http: HttpClient) { }
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = true;

  @Input()
  title: string;
  menu: string;

  userMenuItems = [
      {
      text: 'Hi ' + localStorage.getItem("name"),
      icon: 'user'
    },
    {
      text: 'Logout',
      icon: 'runner',
      onClick: () => {
        this.authService.logOut();
      }
    }];

  titleRoute = () => {
    this._router.navigate(['/documents-management']);
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  }

  ngOnInit() {
    //this.loadBusinessInfo();
   this.vesselname = localStorage.getItem("vesselname");
   this.userName= localStorage.getItem("name");
   this.designation= localStorage.getItem("designation");
  }

  loadBusinessInfo() {
    this.http.get(this.urlBsinfo).subscribe((data: any) => {
      this.bsinfoData = data[0];
      this.companyName = data[0].companyName;
      this.tradingName = data[0].tradingName;
      if (!(!this.bsinfoData.logo || this.bsinfoData.logo == ''))
        this.businessImageUrl = 'data:image/png;base64,' + this.bsinfoData.logo;
    }), catchError((error: Response) => {
      return throwError('Something went wrong');
    });
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class HeaderModule { }

export class Bsinfo {
  abn: string;
  acn: string;
  address: string;
  address2: string;
  clientCode: string;
  companyName: string;
  email: string;
  fax: string;
  logo: any;
  logoData: any;
  mobileNo: string;
  phoneNo: string;
  website: string;
  uidNumber: any;
  insurerName: any;
  commencementDate: any;
  expirationDate: any;
}
