// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-primary {
  margin: 0 auto;
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/defect/defect/defect.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,cAAc;AAChB","sourcesContent":[".btn-primary {\r\n  margin: 0 auto;\r\n  display: block;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
