import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Form } from 'formiojs/types/form';
import { ToastrService } from 'ngx-toastr';
import { interval, take } from 'rxjs';
import { QuestionDto } from 'src/app/shared/Models/QuestionDTO';
import { SprintAnswer } from 'src/app/shared/Models/SprintAnswer';
import { CrewService } from 'src/app/shared/services/crewService';
import { SharedService } from 'src/app/shared/services/sharedService';
import { SpinnerService } from 'src/app/shared/services/spinner.service';

@Component({
  selector: 'app-crew-checklist-questions',
  templateUrl: './crew-checklist-questions.component.html',
  styleUrl: './crew-checklist-questions.component.css'
})
export class CrewChecklistQuestionsComponent {
  constructor(private route: ActivatedRoute,
    private _crewService:CrewService,
    private _sharedService:SharedService,
    private _loggerService:ToastrService,
    private _spnnerService:SpinnerService
  ){

  }
  crewChecklistQuestionid:string;
  form:any;
  checklistData = [
    { id: 1, question: 'What is the maximum draft?', answer: 'As technology continues to advance at a rapid pace...', status: 'Pending' },
    { id: 2, question: 'How often is maintenance performed?', answer: 'No', status: 'Completed' },
    { id: 3, question: 'Are all safety checks up to date?', answer: 'N/A', status: 'In Progress' }
  ];
  popupVisible = false;
  referencePopupVisible:boolean = false;  // Show reference popup on page load
  selectedQuestion: SprintAnswer= new SprintAnswer();
  timerValue: number = 0; // Timer starting from 0 seconds
  sprintChecklistQuestions:any[]=[];
  questionId:string="";
  expectation:boolean=null;
  question:QuestionDto=new QuestionDto();
  selectedDate:any;
  minDate: Date = new Date();

  ngOnInit() {
    this._spnnerService.showLoader();
    this._sharedService.currentQuestionId.subscribe(a=>{
     this.questionId=a;
    });
    this._crewService.getQuestionById(this.questionId).subscribe(res=>{
      this._spnnerService.hideLoader();
      this.question=res;
      this.form=JSON.parse( this.question.roviq);
    })
    this.crewChecklistQuestionid = this.route.snapshot.paramMap.get('id');
    this.getCrewChecklistQuestion(this.crewChecklistQuestionid);

    }

  startTimer() {
    interval(1000).subscribe(() => {
      this.timerValue++;
    });
  }
  getCurrentDate(): string {
    const currentDate = new Date();
    return currentDate.toISOString().split("T")[0];
  }

  getCrewChecklistQuestion(crewChecklistQuestionid){
    this._crewService.getCrewChecklistQuestion(crewChecklistQuestionid).subscribe(res=>{
      this.sprintChecklistQuestions = res.sort((a, b) => a.statusId - b.statusId);
      this.referencePopupVisible = !this.sprintChecklistQuestions[0].isReferenceMaterialSeen;
    });
  }

  editQuestion(question: any) {
    this.selectedQuestion = { ...question };
    this.popupVisible = true;
  }

  saveAnswer(value:boolean) {
    if(this.selectedQuestion.answer==null || this.selectedQuestion.answer.trim()==""){
      this._loggerService.warning("Please enter Answer");
      return;
    }
    if(this.selectedQuestion.expectation==false &&  this.selectedQuestion.tentativeClosureDate==null){
      this._loggerService.warning("Please Select Tentative Closure Date.");
      return;
    }
    this._crewService.saveChecklistAnswer(this.selectedQuestion,value).subscribe((res=>{
      this.getCrewChecklistQuestion(this.crewChecklistQuestionid);
    }));
    this.selectedQuestion =  new SprintAnswer();
    let question =this.sprintChecklistQuestions.find(x=>x.id==this.selectedQuestion.id);
    if(question!=null||undefined){
      this.sprintChecklistQuestions.find(x=>x.id==this.selectedQuestion.id).answer=this.selectedQuestion.answer;
    }
    this.popupVisible = false;
  }

  closePopup() {
    this.popupVisible = false;
    this.expectation=null;
  }
  submitForm(res:any){
    this.referencePopupVisible = false;
    this.expectation=null;
  }

  closeReferencePopup() {
    this.referencePopupVisible = false;
  }
  openReferencePopup(){
    this.referencePopupVisible = true;
  }





}
