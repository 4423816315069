import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Inspection } from 'src/app/shared/Models/Inspection';
import { Program } from 'src/app/shared/Models/Program';
import { ServerResponse } from 'src/app/shared/Models/ServerResponse';
import { Sprint } from 'src/app/shared/Models/Sprint';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root' // Automatically registers it in the root injector
})
export class ReportService {
  constructor(private http: HttpClient) {}
  private token: string | null = null;
  readonly apiURL = environment.server;
  data: any;
  options = {
    headers: this.getHeaders(),
  };
  private toastr: ToastrService;

  getHeaders(): HttpHeaders {
    this.token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    });
    return headers;
  }

  getSprintCountByVessel() {
    return this.http.post<ServerResponse>(
      this.apiURL + 'report/getSprintCountByVessel',
      this.options
    );
  }
getVesselDefectData() {
  return this.http.post<ServerResponse>(
    this.apiURL + 'report/getVesselDefectData',
    this.options
  );
  }
  getDefectVesselScore() {
    return this.http.post<ServerResponse>(
      this.apiURL + 'report/getDefectVesselScore',
      this.options
    );
    }

  getUpcomingInspections() {
    return this.http.post<ServerResponse>(
      this.apiURL + 'report/getUpcomingInspections',
      this.options
    );
    }
    getAssignedQuestionReport(){
      return this.http.post<ServerResponse>(
        this.apiURL + 'report/getAssignedQuestionReport',
        this.options
      );
    }
    getAssignedQueonReportCrew(){
      return this.http.post<ServerResponse>(
        this.apiURL + 'report/getAssignedQueonReportCrew',
        this.options
      );

    }
    getCrewDefectData(){
        return this.http.post<ServerResponse>(
        this.apiURL + 'report/getCrewDefectData',
        this.options
      );

    }
    getQuestionStatusReport(){
      return this.http.post<ServerResponse>(
      this.apiURL + 'report/getQuestionStatusReport',
      this.options
    );

  }


}
