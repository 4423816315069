import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { VesselService } from "../../../shared/services/vessels.service";
import { ProgramService } from "../program.service";
import { Program } from "src/app/shared/Models/Program";
import { map } from "rxjs/operators";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {SpinnerService} from '../../../shared/services/spinner.service';
import { ServerResponse } from "src/app/shared/Models/ServerResponse";
import { Inspection } from "src/app/shared/Models/Inspection";
import { DatePipe } from '@angular/common';

@Component({
  selector: "app-program-add",
  templateUrl: "./add.component.html",
  styleUrl: "./add.component.css",
  providers:[DatePipe]
})
export class AddComponent implements OnInit {
  VesselOptions: any[];
  selectedType: any;
  program: Program = new Program();
  programForm: FormGroup;
  @Input() isShowAddForm: boolean;
  @Output() isShowAddFormChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();
    minDate: Date = new Date();
  previousInspectionDate:any;
  nextInspectionDate:any;

  constructor(
    private programservice: ProgramService,
    private vesselservice: VesselService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private _spinner: SpinnerService,
    private datePipe: DatePipe
  ) {}
  ngOnInit(): void {
    this.programForm = this.formBuilder.group({
      Name: ["", Validators.required],
      PlannedStartDate: [null, Validators.required],
      VesselId: ["", Validators.required],
    });

    this.vesselservice.getVessels().subscribe((res) => {
      this.VesselOptions = res.map((vessel) => ({
        id: vessel.id,
        name: vessel.name,
      }));
    });
  }
  getCurrentDate(): string {
    const currentDate = new Date();
    return currentDate.toISOString().split("T")[0];
  }
  closeAddProgramPopup() {
    this.isShowAddForm = false;
    this.isShowAddFormChange.emit(this.isShowAddForm);
    this.programForm.reset();
  }
  onTypeSelectionChanged(selectedValue: any) {
    this.selectedType = selectedValue;
  }
  onProgramAdd() {

    const startDate = new Date(Date.UTC(
      this.programForm.value.PlannedStartDate.getFullYear(),
      this.programForm.value.PlannedStartDate.getMonth(),
      this.programForm.value.PlannedStartDate.getDate()
  ));
// Format dates as 'YYYY-MM-DD' if necessary for the API
  this.programForm.value.PlannedStartDate = startDate.toISOString().split('T')[0];
    this._spinner.showLoader();
    this.programservice.saveData(this.programForm.value).subscribe(
      (response:ServerResponse) => {
        if(response.success){
          this._spinner.hideLoader();
          this.closeAddProgramPopup();
          this.isShowAddFormChange.emit(true);
          this.programForm.reset();
        }
      },
      (error) => {
        this._spinner.hideLoader();
        this.toastr.error(error.error.message);
      }
    );
  }
  onValueChanged(e:any){
    if(e.value==null) return;
    this.programservice.getInspectionById(e.value).subscribe((res:ServerResponse)=>{
      if(res.success){
        let inspectionList:Inspection[] =res.result;
        if(inspectionList[0]!=null && new Date( inspectionList[0].startDate)<this.minDate){
          this.minDate = new Date(inspectionList[0].startDate);

          this.previousInspectionDate = this.datePipe.transform(inspectionList[0].startDate, 'dd/MM/yyyy');
          this.minDate.setDate(this.minDate.getDate() + 7);
           const today = new Date();
           if (this.minDate < today) {
            this.minDate = today; // Set minDate to today if it's in the past
          }
        }
        else{
          this.toastr.error("Can not create program because last inspection in not present for this vessel");
        }
      }

    })
  }
}
